export default [
  {
    header: 'Orders',
    action: 'read',
    resource: 'order',
  },
  {
    title: 'Overzicht',
    route: 'app-order',
    icon: 'ShoppingBagIcon',
    action: 'read',
    resource: 'order',
  },
  {
    title: 'Order aanmaken',
    route: 'apps-order-flow-checkout',
    icon: 'ShoppingCartIcon',
    action: 'create',
    resource: 'order',
  },
]
