export default [
  {
    header: 'Producten',
  },
  {
    title: 'Product Overzicht',
    route: 'apps-products-list',
    icon: 'PackageIcon',
    action: 'read',
    resource: 'product',
  },
  {
    title: 'Flow',
    route: 'app-flows',
    icon: 'GridIcon',
    action: 'read',
    resource: 'flows',
  },
  {
    title: 'Labels',
    route: 'app-labels-list',
    icon: 'TagIcon',
    action: 'read',
    resource: 'suppliers',
  },
]
