export default [
  {
    header: 'Externe koppeling',
    action: 'read',
    resource: 'hem_external',
  },
  {
    title: 'Gaslicht',
    route: 'app-gaslicht',
    icon: 'Share2Icon',
    action: 'read',
    resource: 'hem_external',
  },
]
